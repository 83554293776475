<template>
  <div id="section-content" class="container container-fluid">
    <div class="bar-back">
      <router-link to="/member/lottery/lottoset"><i class="fas fa-chevron-left"></i> หน้าหลัก</router-link>
    </div>
    <div class="p-2 w-100 bg-light main-content align-self-stretch" style="min-height: calc((100vh - 179px) - 50px)">
      <div class="bgwhitealpha text-secondary shadow-sm rounded p-1 px-2 xtarget col-lotto d-flex flex-row justify-content-between mb-1 pb-0">
        <div class="lotto-title">
            <h4><i class="fas fa-ticket-alt"></i> {{ lotteryInformation ? lotteryInformation.group_name : '' }}</h4>
        </div><!-- end title --->
        <a v-b-modal.rule-by-id href="#" class="btn btn-outline-secondary btn-sm mr-1" >กติกา
            &amp; วิธีเล่น</a>
      </div>

      <div v-if="isLoading">
        <div class="row px-0 mb-1 mx-0">
          <div class="col-4 p-1 pb-0">
            <router-link
              :to="`/member/lottery/lottoset/${id}`"
              style="align-items: center"
              class="btn-af btn btn-outline-primary btn-block d-flex flex-column font_size_0_9em pt-2 pb-2">
              <i class="fas fa-award"></i>
              ผล{{ lotteryInformation ? lotteryInformation.group_name : '' }}
            </router-link>
          </div>
          <div class="col-4 p-1 pb-0">
            <router-link
              style="align-items: center"
              :to="`/member/lottery/lottoset/${id}/tang`"
              class="btn-af btn btn-outline-primary btn-block d-flex flex-column font_size_0_9em pt-2 pb-2">
              <i class="fas fa-calculator"></i>
              แทง{{ lotteryInformation ? lotteryInformation.group_name : '' }}
            </router-link>
          </div>
           <div class="col-4 p-1 pb-0">
            <router-link
              style="align-items: center"
              :to="`/member/lottery/lottoset/${id}/poy`"
              class="btn-af btn btn-outline-primary btn-block d-flex flex-column active font_size_0_9em pt-2 pb-2">
              <i class="fas fa-calculator"></i>
              รายการที่แทง
            </router-link>
          </div>
        </div>
        <div class="w-100 my-2 border-bottom"></div>
         <div class="bgwhitealpha shadow-sm rounded p-2 mb-1 xtarget col-lotto d-flex flex-column flex-sm-column flex-md-row justify-content-between">
              <h4 class="mb-0 text-center"><i class="fas fa-list"></i> รายการที่แทง</h4>
              <div><span class="badge badge-primary font-weight-light w-100">ผลรางวัลออกเลขตามสลากหวย ({{ lotteryInformation ? lotteryInformation.group_name : '' }})</span></div>
          </div>
        <div class="bg-white shadow-sm rounded py-2 px-1 mb-5">
          <template >
              <div class="row p-0 m-0 mb-2 border border-dark" v-for="(item,index) in setList" :key="index">
                <div class="col-2 col-sm-2 col-md-1 d-flex flex-column justify-content-center align-items-center bg-secondary text-white p-1"><small style="line-height:1;">ลำดับ</small><b>{{ index+1 }}</b></div >
                <div class="col-6 col-sm-6 col-md-9 p-1 bg-light border border-left-0 border-right-0 border-top-0 d-flex justify-content-center align-items-center"><h4 style="letter-spacing:5px;" class="mb-0">{{ item.number }}</h4></div>
                <div class="col-4 col-sm-4 col-md-2 bg-warning d-flex justify-content-center align-items-center px-0" v-if="item.status == 0 || item.status == 1">
                  <small style="line-height:1.2"><i class="fas fa-circle-notch fa-spin"></i> {{ item.statusName }}</small>
                </div>
                <div class="col-4 col-sm-4 col-md-2 bg-lost d-flex justify-content-center align-items-center px-0"  :class=" item.status == 2  ? 'bg-danger' : 'bg-success'" v-else-if="item.status == 2">
                  <small style="line-height:1.2">{{ item.statusName }}</small>
                </div>
                <div class="col-4 col-sm-4 col-md-2 bg-lost d-flex justify-content-center align-items-center px-0" :class=" item.IsWinPrice  ? 'bg-success' : 'bg-primary'" v-else>
                  <small style="line-height:1.2">{{ item.IsWinPrice ? 'ถูกรางวัล' : 'ไม่ถูกรางวัล' }}</small>
                </div>
                <div class="w-100"></div>
                <div class="col-4 col-sm-4 col-md-1 bg-dark text-white pt-1 px-1"><small>No. #{{ item.id}}</small></div>
                <div class="col-8 col-sm-8 col-md-3 bg-dark text-white text-right text-sm-right text-md-center pt-1 px-1"><small class="smallspan"><span>ซื้อเมื่อ</span><span>{{ getDate(item.dateBetting) }}</span></small></div>
                <div class="col-8 col-sm-8 col-md-3 bg-dark text-white text-left text-sm-left text-md-right pt-1 px-1"><small><span></span>
                  <span class="px-0" v-if="item.status==0"><a @click="confirmReturn(item.id, item.number)" class="btn btn-sm btn-outline-danger w-50" style="text-decoration: none;" target="_blank" >คืนโพย</a></span>
                </small></div>
                <div class="col-4 col-sm-4 col-md-3 bg-dark text-white text-right pt-1 px-1"><small>จำนวน {{ item.amount }} ชุด</small></div>
                <div class="col-12 col-sm-12 col-md-2 bg-light border border-secondary border-bottom-0 text-dark text-center text-sm-center text-md-right pt-1 px-1"><b><small>ราคา</small> {{ item.amount * 120 }} ฿</b></div>
                <!-- <div class="col-12 col-sm-12 col-md-2 bg-dark text-white text-right p-1">
                    <a href="#" class="btn btn-sm btn-secondary btn-block py-0 px-1" data-toggle="modal" data-target="#laosPoy" id="1994-1-LA2330154-1630148400"><i class="fas fa-save"></i> บันทึกสลาก</a>
                </div> -->
              </div>
          </template>
        </div>
      </div>
    </div>
    <app-rule-by-id-modal :advice="this.advice" />
  </div>
</template>

<script>
import moment from 'moment'
import ruleByIdModal from '@/components/modals/RuleByIdModalHtml'
import { mapActions, mapGetters } from 'vuex'
import { listAllBetHistoryService, returnBetServiceById } from '@/services'
export default {
  components: {
    'app-rule-by-id-modal': ruleByIdModal
  },
  async created () {
    this.id = parseInt(this.$route.params.id)
    try {
      this.setLoading(true)
      await this.getInquiryRoundInfomationByID(this.id)
      this.lotteryInformation = this.getInquiryRoundInfomation
      this.advice = this.lotteryInformation.advice
      await this.fetchResultLotto(this.$route.params.id)
      const resp = await listAllBetHistoryService()
      const data = resp.data.map(e => {
        return {
          ...e,
          numberbet: e.numberbet,
          WinAmount: parseInt(e.WinAmount),
          IsWinPrice: JSON.parse(e.IsWinPrice),
          dateBetting: moment(e.dateBetting)
        }
      })
      this.list = data.filter(v => (Number(v.group_id) === Number(3) && Number(this.lotteryInformation.lot_type_id) === Number(v.lot_type_id)))
      this.setList = []
      this.list.forEach((value, index) => {
        value.numberbet[8].list.forEach((valueSub, indexSub) => {
          this.setList.push({
            id: value.TicketId,
            number: valueSub,
            amount: value.numberbet[8].amount[indexSub],
            dateBetting: moment(value.dateBetting),
            status: value.status,
            price: value.price,
            statusName: value.statusName,
            title: value.title,
            IsWinPrice: value.IsWinPrice,
            TypeBet: value.TypeBet
          })
        })
      })

      this.setLoading(false)
    } catch (error) {
      this.setLoading(false)
    }
    this.isLoading = true
  },
  methods: {
    ...mapActions(['setLoading', 'fetchResultLotto', 'getInquiryRoundInfomationByID', 'getBalanceInfo']),
    getDate (date) {
      return moment(date).format('DD-MM-YYYY')
    },
    confirmReturn (ticket_id, number_) {
      this.$swal({
        title: 'ยืนยันการคืนลอตเตอรี่ ?',
        text: 'คุณต้องการคืนลอตเตอรี่ ' + number_ + ' ใบนี้ใช่ไหม!',
        icon: 'success',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ใช่',
        cancelButtonText: 'ไม่ใช่'
      }).then((result) => {
        if (result.isConfirmed) {
          this.callSubmitReturnTicket(ticket_id)
        }
      })
    },
    async callSubmitReturnTicket (ticket_id) {
      this.setLoading(true)
      if (this.enable_del_ticket) {
        this.enable_del_ticket = false
        const respById = await returnBetServiceById(ticket_id)
        if (respById.status_code === 'SUCCESS') {
          const resp = await listAllBetHistoryService()
          const data = resp.data.map(e => {
            return {
              ...e,
              numberbet: e.numberbet,
              WinAmount: parseInt(e.WinAmount),
              IsWinPrice: JSON.parse(e.IsWinPrice),
              dateBetting: moment(e.dateBetting)
            }
          })
          this.list = data.filter(v => (Number(v.group_id) === Number(3) && Number(this.lotteryInformation.lot_type_id) === Number(v.lot_type_id)))
          this.setList = []
          this.list.forEach((value, index) => {
            value.numberbet[8].list.forEach((valueSub, indexSub) => {
              this.setList.push({
                id: value.TicketId,
                number: valueSub,
                amount: value.numberbet[8].amount[indexSub],
                dateBetting: moment(value.dateBetting),
                status: value.status,
                price: value.price,
                statusName: value.statusName,
                title: value.title,
                IsWinPrice: value.IsWinPrice,
                TypeBet: value.TypeBet
              })
            })
          })
          await this.getBalanceInfo()
          this.setLoading(false)
          this.enable_del_ticket = true
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'คืนโพยสำเร็จ',
            showConfirmButton: false,
            timer: 1500
          })
        } else {
          this.setLoading(false)
          this.enable_del_ticket = true
        }
      }
    }
  },
  computed: {
    ...mapGetters(['getInquiryRoundInfomation', 'getResultLotto'])
  },
  data () {
    return {
      id: null,
      lotto: {},
      isLoading: false,
      advice: '',
      lotteryInformation: null,
      list: [],
      setList: [],
      countIndex: 1,
      enable_del_ticket: true
    }
  }
}
</script>

<style>
</style>
